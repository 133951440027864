<template>
  <div>
    <van-nav-bar
      title="quay lại"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >quay lại</span
        >
      </template>
    </van-nav-bar>
    <img class="image" :src="require(`@/assets/images/12.png`)" />
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  margin-top: 100px;
}
.iconfont {
  font-size: 40px;
}
</style>
